function isRobot(userAgent) {
  const robots = new RegExp(
    [
      /bot/,
      /spider/,
      /crawl/,
      /APIs-Google/,
      /AdsBot/,
      /Googlebot/,
      /mediapartners/,
      /Google Favicon/,
      /FeedFetcher/,
      /Google-Read-Aloud/,
      /DuplexWeb-Google/,
      /googleweblight/,
      /bing/,
      /yandex/,
      /baidu/,
      /duckduck/,
      /yahoo/,
      /ecosia/,
      /ia_archiver/,
      /facebook/,
      /instagram/,
      /pinterest/,
      /reddit/,
      /slack/,
      /twitter/,
      /whatsapp/,
      /youtube/,
      /semrush/,
    ]
      .map((r) => r.source)
      .join("|"),
    "i"
  );

  return robots.test(userAgent);
}

function createAgeCheckerElement() {
  const css = `
    .agc_no_scroll {
        overflow: hidden !important;
    }
    @media only screen and (max-width: 768px) {
        .agc_no_scroll {
            height: 100% !important;
            margin: 0 !important;
            padding: 0 !important;
        }
    }
    .agc_screen * {
        margin: 0;
        padding: 0;
        line-height: 1.2;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
    }
    .agc_screen #agc_verify_button {
        font-family: 'Montserrat', sans-serif;
        padding: 15px 62px;
        text-align: center;
        background-color: #c04848;
        border: 1px solid white;
        background-color: transparent;
        font-size: 18px;
        font-weight: bold;
        color: white;
        cursor: pointer;
        text-transform: none;
        outline: none;
        -webkit-border-radius: 25px;
        -moz-border-radius: 25px;
        border-radius: 25px;
    }
    .agc_screen #agc_verify_button:hover {
        background-color: white;
        color: #333;
        -webkit-transition: background-color 0.2s;
        -moz-transition: background-color 0.2s;
        -o-transition: background-color 0.2s;
        transition: background-color 0.2s;
    }
    .agc_screen#agc_bg {
        position: fixed;
        z-index: 999999999;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.90);
        background-position: center center;
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-size: cover;
    }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
        .agc_screen#agc_bg {
            -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
            background-color: rgba(0, 0, 0, 0.7);
        }
    }
    .agc_screen#agc_form {
        position: fixed;
        z-index: 999999999;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        font-family: 'Montserrat', sans-serif;
        font-weight: normal;
        font-size: 16px;
        color: white;
        overflow: hidden;
        overflow-y: auto;
    }
    .agc_screen #agc_container {
        position: absolute;
        max-height: 100%; /* Allows container to overflow correctly in small screen heights */
        z-index: 2;
        bottom: 50%;
        right: 50%;
        width: 100%;
        text-align: center;
        max-width: 700px;
        padding: 10px;
        -ms-transform: translate(50%, 50%);
        -moz-transform: translate(50%, 50%);
        -webkit-transform: translate(50%, 50%);
        transform: translate(50%, 50%);
    }
    .agc_screen #agc_container #agc_title {
        margin: 0px 0px 30px 0px;
        font-size: 30px;
        font-weight: bold;
    }
    .agc_screen #agc_container p {
        margin: 0px 0px 30px 0px;
        font-size: 16px;
    }
  `;
  const head = document.head || document.getElementsByTagName("head")[0];
  const style = document.createElement("style");

  style.setAttribute("id", "agc_style");
  style.appendChild(document.createTextNode(css));
  head.appendChild(style);

  document.body.classList.add("agc_no_scroll");

  const divBg = document.createElement("div");
  divBg.setAttribute("id", "agc_bg");
  divBg.classList.add("agc_screen");
  document.body.appendChild(divBg);

  const aside = document.createElement("aside");
  aside.classList.add("agc_screen");
  aside.setAttribute("id", "agc_form");

  const div = document.createElement("div");
  div.setAttribute("id", "agc_container");

  const pTitle = document.createElement("p");
  pTitle.setAttribute("id", "agc_title");
  pTitle.innerText = "INTERDIT AUX MOINS DE 18 ANS - ATTENTION !";
  div.appendChild(pTitle);

  const p = document.createElement("p");
  const pText = document.createTextNode(
    `En pénétrant sur LovelyPleasure.fr, je suis averti que ce site comporte des vidéos, des images et des textes à caractères sexuels pouvant heurter la sensibilité de certaines personnes. En cliquant sur "Oui, je suis MAJEUR", je certifie avoir l'âge légal de la majorité dans mon pays, de ne pas faire connaître ce service à des mineurs, sous peine de sanctions pénales, de ne pas poursuivre LovelyPleasure.fr pour toute action judiciaire. Si ce n'est pas le cas nous vous invitons à fermer la page.`
  );
  p.appendChild(pText);
  div.appendChild(p);

  const divButton = document.createElement("div");
  divButton.setAttribute("id", "agc_verify_submit");
  const aEnter = document.createElement("button");
  aEnter.type = "submit";
  aEnter.setAttribute("id", "agc_verify_button");
  aEnter.innerText = "Oui, je suis MAJEUR";
  aEnter.onclick = function () {
    localStorage.setItem("age-checker", "1");
    removeAgeCheckerElement();
  };
  divButton.appendChild(aEnter);
  div.appendChild(divButton);

  aside.appendChild(div);
  document.body.appendChild(aside);
}

function removeAgeCheckerElement() {
  document.body.classList.remove("agc_no_scroll");
  document.getElementById("agc_form").remove();
  document.getElementById("agc_style").remove();
}

function injectAgeChecker() {
  if (isRobot(navigator.userAgent)) {
    return;
  }

  const age = localStorage.getItem("age-checker");

  if (age) {
    return;
  }

  if (!document.getElementById("agc_form")) {
    createAgeCheckerElement();
  }
}

export const onRouteUpdate = () => {
  injectAgeChecker();
};
